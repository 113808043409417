@import './dark-theme';
@import './light-theme';

// Static colors. Won't change based on theme selected.
html {
  // Orange
  --color-orange-100: #ff5f00;
  --color-orange-200: #e95601;

  // Greyscale
  --color-white: #FFFFFF;
  --color-black: #000000;
  --color-static-100: #F7FAFA;
  --color-static-200: #EDF2F3;
  --color-static-300: #E2E8EB;
  --color-static-400: #CBD5D9;
  --color-static-500: #A0AEB6;
  --color-static-600: #718086;
  --color-static-700: #4A555A;
  --color-static-800: #2D373E;
  --color-static-900: #1A2021;

  // Reds
  --color-red-050: #FFE3E3;
  --color-red-100: #FFBDBD;
  --color-red-200: #FF9B9B;
  --color-red-300: #F86A6A;
  --color-red-400: #EF4E4E;
  --color-red-500: #E12D39;
  --color-red-600: #CF1124;
  --color-red-700: #AB091E;
  --color-red-800: #8A041A;
  --color-red-900: #610316;

  // Blues
  --color-blue-050: #005cb9; // official ciee blue
  --color-blue-100: #00468A; // other official ciee blue
  --color-blue-200: #9EB3CD;
  --color-blue-300: #166BD440;
  --color-blue-400: #166BD480;
  --color-blue-500: #263951;
  --color-blue-600: #466489;
  --color-blue-700: #90a9c6;
  --color-blue-800: #52749f;
  --color-blue-900: #49688e;

  // Greens
  --color-green-100: #E3F9E5;
  --color-green-200: #C1F2C7;
  --color-green-300: #91E697;
  --color-green-400: #51CA58;
  --color-green-500: #31B237;
  --color-green-600: #18981D;
  --color-green-700: #0F8613;
  --color-green-800: #0E7817;
  --color-green-900: #07600E;
  --color-green-1000: #014807;

  // Cyans/Teals
  --color-cyan-050: #E0FCFF;
  --color-cyan-100: #BEF8FD;
  --color-cyan-200: #87EAF2;
  --color-cyan-300: #54D1DB;
  --color-cyan-400: #38BEC9;
  --color-cyan-500: #2CB1BC;
  --color-cyan-600: #14919B;
  --color-cyan-700: #0E7C86;
  --color-cyan-800: #0A6C74;
  --color-cyan-900: #044E54;

  // yellow
  --color-yellow-050: #FFFAEB;
  --color-yellow-100: #FCEFC7;
  --color-yellow-200: #F8E3A3;
  --color-yellow-300: #F9DA8B;
  --color-yellow-400: #F7D070;
  --color-yellow-500: #E9B949;
  --color-yellow-600: #C99A2E;
  --color-yellow-700: #A27C1A;
  --color-yellow-800: #7C5E10;
  --color-yellow-900: #513C06;


  // Shadows
  --color-shadow-100: #00000019;
  --color-shadow-200: #00000033;
  --color-shadow-250: #00000040;
  --color-shadow-300: #0000004C;
}



