.theme-light {
  --color-base-000: var(--color-white);
  --color-base-100: var(--color-static-100);
  --color-base-200: var(--color-static-200);
  --color-base-300: var(--color-static-300);
  --color-base-400: var(--color-static-400);
  --color-base-500: var(--color-static-500);
  --color-base-600: var(--color-static-600);
  --color-base-700: var(--color-static-700);
  --color-base-800: var(--color-static-800);
  --color-base-900: var(--color-static-900);
  --color-base-1000:var(--color-black);


  --color-text-100: #464646
}
