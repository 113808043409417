/* You can add global styles to this file, and also import other style files */
@import './scss/colors';

.grid {
  margin-right: 0em;
  margin-left: 0em;
  margin-top: 0em;
}

.container-height-calc {
  height: calc(100vh - 5rem);
}

.search-item-submenu {
  background: none !important;
}

.p-picklist .p-picklist-list {
  height: 13em;
}

.layout-rightpanel .right-panel-header .title h1 {
  margin: 0;
}

.p-treetable .p-treetable-tbody > tr > td, .p-datatable .p-datatable-tbody > tr > td {
  vertical-align: middle;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #ffffff;
  border-bottom: 2px solid black;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.toggle-button.p-selectbutton .p-button {
  background: none;
  color: #212529;
  border-color: #ced4da;
  padding-left: 20px;
  padding-right: 20px;

  &:hover {
    background: #5E81AC !important;
    color: #ffffff !important;
    border-color: #ced4da !important;
  }

  &.p-highlight {
    background: #51749e;
    color: #ffffff;

    &:hover {
      background: #51749e !important;
    }
  }
}