*,
::before,
::after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
}

html {
  line-height: 1.15;
  
  -webkit-text-size-adjust: 100%;
  
}

body {
  margin: 0;
}

body {
  font-family:
		system-ui,
		-apple-system, 
		'Segoe UI',
		Roboto,
		Helvetica,
		Arial,
		sans-serif,
		'Apple Color Emoji',
		'Segoe UI Emoji';
}

hr {
  height: 0;
  
  color: inherit;
  
}

b {
  font-weight: bolder;
}

code {
  font-family:
		ui-monospace,
		SFMono-Regular,
		Consolas,
		'Liberation Mono',
		Menlo,
		monospace;
  
  font-size: 1em;
  
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  text-indent: 0;
  
  border-color: inherit;
  
}

button,
input,
select,
textarea {
  font-family: inherit;
  
  font-size: 100%;
  
  line-height: 1.15;
  
  margin: 0;
  
}

button,
select {
  
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  
  outline-offset: -2px;
  
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  
  font: inherit;
  
}

summary {
  display: list-item;
}

dd,
h1,
h2,
h3,
h4,
h6,
hr,
figure,
p {
  margin: 0;
}

button {
  background-color: transparent;
  background-image: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  
  line-height: 1.5;
  
}

body {
  font-family: inherit;
  line-height: inherit;
}

*,
::before,
::after {
  box-sizing: border-box;
  
  border-width: 0;
  
  border-style: solid;
  
  border-color: currentColor;
  
}

hr {
  border-top-width: 1px;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #a1a1aa;
}

button,
[role="button"] {
  cursor: pointer;
}

:-moz-focusring {
  outline: auto;
}

table {
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button,
input,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

code {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

img,
svg,
iframe,
object {
  display: block;
  
  vertical-align: middle;
  
}

img {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, ::before, ::after {
  border-color: currentColor;
}

.tw-relative {
  position: relative !important;
}

.tw-float-right {
  float: right !important;
}

.tw-float-left {
  float: left !important;
}

.tw-m-2 {
  margin: 0.5rem !important;
}

.tw-m-64 {
  margin: 16rem !important;
}

.tw-m-auto {
  margin: auto !important;
}

.tw-mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.tw-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.tw-my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.tw-my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.tw-my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.tw-my-64 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.tw-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.tw-mt-2 {
  margin-top: 0.5rem !important;
}

.tw-mt-4 {
  margin-top: 1rem !important;
}

.tw-mr-2 {
  margin-right: 0.5rem !important;
}

.tw-mb-1 {
  margin-bottom: 0.25rem !important;
}

.tw-mb-2 {
  margin-bottom: 0.5rem !important;
}

.tw-mb-4 {
  margin-bottom: 1rem !important;
}

.tw-ml-1 {
  margin-left: 0.25rem !important;
}

.tw-ml-2 {
  margin-left: 0.5rem !important;
}

.tw-block {
  display: block !important;
}

.tw-inline-block {
  display: inline-block !important;
}

.tw-inline {
  display: inline !important;
}

.tw-flex {
  display: flex !important;
}

.tw-grid {
  display: grid !important;
}

.tw-h-8 {
  height: 2rem !important;
}

.tw-h-12 {
  height: 3rem !important;
}

.tw-h-32 {
  height: 8rem !important;
}

.tw-h-full {
  height: 100% !important;
}

.tw-h-2\/4 {
  height: 50% !important;
}

.tw-h-screen {
  height: 100vh !important;
}

.tw-min-h-full {
  min-height: 100% !important;
}

.tw-min-h-3\/4 {
  min-height: 75% !important;
}

.tw-w-1 {
  width: 0.25rem !important;
}

.tw-w-12 {
  width: 3rem !important;
}

.tw-w-auto {
  width: auto !important;
}

.tw-w-2\/4 {
  width: 50% !important;
}

.tw-w-3\/4 {
  width: 75% !important;
}

.tw-w-full {
  width: 100% !important;
}

.tw-w-fit {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

@keyframes tw-spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes tw-ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes tw-pulse {
  50% {
    opacity: .5;
  }
}

@keyframes tw-bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.tw-cursor-pointer {
  cursor: pointer !important;
}

.tw-flex-col {
  flex-direction: column !important;
}

.tw-justify-center {
  justify-content: center !important;
}

.tw-justify-between {
  justify-content: space-between !important;
}

.tw-gap-2 {
  gap: 0.5rem !important;
}

.tw-overflow-hidden {
  overflow: hidden !important;
}

.tw-rounded-full {
  border-radius: 9999px !important;
}

.tw-rounded-r-none {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.tw-rounded-r-full {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.tw-rounded-l-none {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.tw-rounded-l-full {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.tw-bg-white {
  background-color: var(--color-white) !important;
}

.tw-bg-static-800 {
  background-color: var(--color-static-800) !important;
}

.tw-bg-blue-100 {
  background-color: var(--color-blue-100) !important;
}

.tw-p-1 {
  padding: 0.25rem !important;
}

.tw-p-2 {
  padding: 0.5rem !important;
}

.tw-p-8 {
  padding: 2rem !important;
}

.tw-py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.tw-pr-1 {
  padding-right: 0.25rem !important;
}

.tw-pb-8 {
  padding-bottom: 2rem !important;
}

.tw-pl-1 {
  padding-left: 0.25rem !important;
}

.tw-text-left {
  text-align: left !important;
}

.tw-text-center {
  text-align: center !important;
}

.tw-align-middle {
  vertical-align: middle !important;
}

.tw-align-text-bottom {
  vertical-align: text-bottom !important;
}

.tw-text-2xl {
  font-size: 1.5rem !important;
}

.tw-text-3xl {
  font-size: 1.875rem !important;
}

.tw-text-5xl {
  font-size: 3rem !important;
}

.tw-text-6xl {
  font-size: 4rem !important;
}

.tw-font-semibold {
  font-weight: 600 !important;
}

.tw-font-bold {
  font-weight: 700 !important;
}

.tw-leading-none {
  line-height: 1 !important;
}

.tw-text-white {
  color: var(--color-white) !important;
}

.tw-text-base-100 {
  color: var(--color-base-100) !important;
}

.tw-text-base-800 {
  color: var(--color-base-800) !important;
}

.tw-text-base-850 {
  color: var(--color-text-100) !important;
}

.tw-no-underline {
  text-decoration: none !important;
}

.hover\:tw-underline:hover {
  text-decoration: underline !important;
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000;
}

.focus\:tw-shadow-none:focus {
  --tw-shadow: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(147, 197, 253, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.page-title {
  padding-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-text-100);
}

@media (min-width: 768px) {

  .md\:tw-mr-2 {
    margin-right: 0.5rem !important;
  }

  .md\:tw-flex {
    display: flex !important;
  }

  .md\:tw-w-auto {
    width: auto !important;
  }
}